.userSearchInput .ant-form-item {
  margin: unset !important;
}

.userSearchInput .ant-input-affix-wrapper {
  border: none;
}

.userTable .ant-table-thead tr .ant-table-cell {
  background-color: #798478;
  color: white;
  font-size: 1rem;
  font-weight: 500;
}

@media screen and (max-width: 1079px) {
  .userTable .ant-table-thead tr .ant-table-cell {
    font-size: 0.8rem;
  }
}

.ant-table-tbody > tr > td {
  font-size: 1rem;
}

.userTable .ant-table-thead tr .ant-table-cell::before {
  display: none;
}

.regularUserTableBtn {
  /* background-color: #798478; */
  font-weight: 600;
  color: white;
  font-size: 1rem;
  width: auto;
  height: 3rem;
}

.redsUserTableBtn {
  background-color: #bf2114 !important;
  font-weight: 600;
  color: white !important;
  font-size: 1rem;
  width: auto;
  height: 3rem;
}

.customThemeCard {
  background-color: #ffffff;
}

.users-custom-table {
  thead tr th {
    background-color: #465f7b !important;
    font-size: 1rem;
    font-weight: 500;
    color: #fff !important;
    border: 0 !important;
    border-bottom: 1px solid #fff !important;
    text-align: center !important;
    padding: 11px 11px !important;
    font-weight: normal !important;
  }

  .ant-table-cell::before {
    background-color: unset !important;
  }

  td {
    text-align: center !important;
  }

  .ant-table-cell {
    font-size: 1rem !important;
    text-align: center;
  }

  .ant-form-item {
    margin-bottom: unset !important;

    .ant-form-item-control-input-content {
      font-size: 1rem;
    }
  }
}

.empty-table-tbody {
  .ant-table-tbody {
    display: none !important;
  }
}

.table-header {
  .ant-table-content {
    scrollbar-width: none;
  }
}

.users-tabs-view {
  .ant-tabs-tab-btn {
    width: 100%;
  }

  .ant-tabs-nav-wrap {
    overflow: unset !important;
  }

  .ant-tabs-tab {
    margin: unset !important;
    width: 100%;
  }

  .ant-tabs-nav-list {
    gap: 10px;
  }

  .ant-tabs-nav-operations {
    display: none !important;
  }
}

.additional-info-modal {
  .tab-headers {
    display: flex;
    justify-content: space-around;
  }

  .ant-form-item {
    width: 100%;

    .ant-radio-group {
      width: 100%;
    }

    .ant-radio-button-wrapper {
      width: calc(100% / 3);
      text-align: center;
    }
  }

  @media (max-width: 786px) {
    padding: unset !important;
    max-width: 100% !important;

    .ant-card-body {
      padding: 10px 10px;
    }

    .ant-modal-content {
      padding: 15px 15px;
    }
  }
}

.recalculate-button {
  /* background-color: #bf2114; */
  font-size: 1rem;
  height: 1.5rem;
  padding: 0px 5px !important;
  margin: 0px !important;
}

.user-profile-name {
  display: flex;
  align-content: center;
  align-items: center;
  font-size: 2rem;
}

.user-profile-tag {
  display: flex;
  align-items: center;
  font-size: 1rem;
  height: 2rem;
}
.user-profile-balance {
  font-size: 1.2rem;
}
.user-profile-ledger {
  color: red;
  cursor: pointer;
}

.edit-user-byhold {
  margin-top: auto;
  width: 80%;
  align-self: center;
  border-radius: 2px;
}

.edit-button-text {
  margin-top: 5px;
}

.account-table-skeleton {
  display: flex;
  flex-direction: column;
  padding: 3.5rem 1rem;

  .ant-skeleton {
    display: flex;
    height: 45px;
    align-items: center;
  }
}

.user-profile-totalChilds {
  text-align: center;
  /* border: 2px solid #465f7b; */
  border-radius: 5px;
  cursor: pointer;

  .ant-tag-magenta {
    background-color: #465f7b;
    color: white;
  }
}

.chid-list-modal-div {
  max-height: 250px;
  padding: 0.5rem;
  overflow: auto;
}

.total-child-number {
  font-size: 4rem;
  border: 2px solid #465f7b;
  border-radius: 5px;
  padding: 0;
}
.total-childTag {
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
}
